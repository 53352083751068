import { mapGetters } from 'vuex'

export default {
  data: () => ({
    // just right after the scan, the vuex connection to the offer object is sometimes
    // not correctly updated in copmuted \offerScanStatus\, so we need to store the scan result temporarily
    temporaryOfferScanStatus: null, // used to store the scan result temporarily
    // hardcoded tariffs that have scans.
    corvusScanTarifs: [
      'corvus-rc1-sideletter',
      'corvus-rc2-sideletter',
      'corvus-rc3-sideletter',
      'corvus-rc4-sideletter',
      'corvus-rc5-sideletter',
    ],
  }),
  // TODO
  // all the scan related fcs should be moved here from the offer component
  computed: {
    ...mapGetters({
      localMixinOffer: 'offer',
    }),
    offerScanStatusMixin() {
      // the computed prop has problems to detect changes in nested objects
      // therefore we use the temp data, that will be erased once the user updates
      // this cover the time section between the submission and the user refresh.
      if (this.temporaryOfferScanStatus && !this.localMixinOffer?.corvusQuoteStatus) {
        return this.temporaryOfferScanStatus
      }
      return this.localMixinOffer?.corvusQuoteStatus
    },
    isQuoteBindingExpiredMixin() {
      const createdAt = this.localMixinOffer.corvusQuoteCreatedDate
      const now = new Date()

      if (!createdAt) {
        return false
      }

      const timeDiff = Math.abs(new Date(createdAt).getTime() - now.getTime())
      const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))

      return diffDays > 45
    },
    hasPositiveScanStatusMixin() {
      return Boolean(this.offerScanStatusMixin === 'QUOTES_SHARED')
    },
  },
  methods: {
    isQuoteCheckAvailableMixin(insurerKey) {
      return (this.tariffHasScanMixin(insurerKey) && this.localMixinOffer?.insurer === insurerKey && this.offerScanStatusMixin === 'SUBMITTED')
    },
    isQuoteCheckDoneMixin(insurerKey) {
      return (this.tariffHasScanMixin(insurerKey) && this.localMixinOffer?.insurer === insurerKey && this.offerScanStatusMixin === 'QUOTE_SHARED')
    },
    hasOfferScanStatusMixin() {
      return Boolean(this.offerScanStatusMixin)
    },
    tariffHasScanMixin(tariffKey) {
      return this.corvusScanTarifs.some((t) => t === tariffKey)
    },
    updateScanResultMixin(update) {
      this.temporaryOfferScanStatus = update
    },
    isRecommendationDeclinedMixin(insurerKey) {
      return this.tariffHasScanMixin(insurerKey) && this.localMixinOffer && this.localMixinOffer.corvusQuoteStatus === 'DECLINED'
    },
    isResubmitMixin(insurerKey) {
      return (
        (this.tariffHasScanMixin(insurerKey) && this.localMixinOffer && this.localMixinOffer.corvusQuoteStatus === 'DECLINED') ||
        this.isQuoteBindingExpiredMixin
      )
    },
  },
}
