<template>
    <div class="questions-wrapper">
        <div class="" v-if="offer && !success">
            <div class="insurerSelected questions questions--insurer-selected"
                v-show="insurerSelected && (currentView === 'questions' || isPrintMode)">
                <div class="questions__title">
                    <h2>Risikofragen <template v-if="selectedInsurer">- <b>{{selectedInsurer.label}}</b></template></h2>
                </div>
                <div class="question" v-for="(question, questionIndex) in filteredQuestions" :key="question.id">
                    <div class="row">
                        <div class="col-12 col-sm-8 question__text-wrapper">
                            <div class="question__content question__block">
                                <div class="question__title question__block" v-if="question.title">
                                    <strong class="question__num">Frage {{ questionIndex + 1 }}:</strong>
                                    <div class="question__title-text">{{
                                    question.title
                                    }}</div>
                                </div>
                                <div
                                    v-if="question.tooltip"
                                    class="question__tooltip question__block"
                                >
                                    <div class="icon-container">
                                        <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.3335 8.99992H7.66683V10.3333H6.3335V8.99992ZM6.3335 3.66659H7.66683V7.66658H6.3335V3.66659ZM7.00016 0.333252C3.3135 0.333252 0.333496 3.33325 0.333496 6.99992C0.333496 8.76803 1.03587 10.4637 2.28612 11.714C2.90517 12.333 3.6401 12.8241 4.44894 13.1591C5.25778 13.4941 6.12468 13.6666 7.00016 13.6666C8.76827 13.6666 10.464 12.9642 11.7142 11.714C12.9644 10.4637 13.6668 8.76803 13.6668 6.99992C13.6668 6.12444 13.4944 5.25753 13.1594 4.4487C12.8243 3.63986 12.3333 2.90493 11.7142 2.28587C11.0952 1.66682 10.3602 1.17575 9.55139 0.840722C8.74255 0.50569 7.87564 0.333252 7.00016 0.333252M7.00016 12.3333C5.58567 12.3333 4.22912 11.7713 3.22893 10.7712C2.22873 9.77096 1.66683 8.41441 1.66683 6.99992C1.66683 5.58543 2.22873 4.22888 3.22893 3.22868C4.22912 2.22849 5.58567 1.66659 7.00016 1.66659C8.41465 1.66659 9.7712 2.22849 10.7714 3.22868C11.7716 4.22888 12.3335 5.58543 12.3335 6.99992C12.3335 8.41441 11.7716 9.77096 10.7714 10.7712C9.7712 11.7713 8.41465 12.3333 7.00016 12.3333V12.3333Z" fill="#EE2485"/>
                                        </svg>
                                    </div>

                                    <span v-html="question.tooltip"></span>
                                </div>
                                <div v-if="question.text" class="question__text question__block">
                                    {{ question.text }}
                                </div>
                                <ul v-if="question.list" class="question__list question__block">
                                    <li v-for="(item, index) in question.list" :key="index"
                                        :class="{ 'question__list--subtext': item.subText }">
                                        <span v-if="item.text" v-html="$sanitizeHtml(item.text)"></span>
                                        <span v-if="item.subText" v-html="$sanitizeHtml(item.subText)"></span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4 d-flex justify-content-end question__answers-wrapper">
                            <div class="question__answers text-right"
                                v-if="!(question.form && question.form.fields)">
                                <button
                                    v-for="(answer, idx) in getAnswers(question)"
                                    :key="'answer-' + idx"
                                    type="button"
                                    @click="answerQuestion(question.id, answer.value)"
                                    class="btn btn-default mr-2"
                                    :class="{
                                      'btn-success': isSelected(question.id, answer.value)
                                    }"
                                    :disabled="checkAnswers"
                                >
                                    {{ answer.label }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mb-4" v-show="!insurerSelected && (currentView === 'questions' || isPrintMode)">
                <div v-for="insurerQuestions in allQuestions" :key="'insurerQuestions-' + insurerQuestions.insurer" class="questions">
                    <div class="questions__title">
                        <h2>Risikofragen - <b>{{ insurerQuestions.insurer }}</b></h2>
                    </div>
                    <div class="question" v-for="(question, questionIndex) in insurerQuestions.questions"
                        :key="question.id">
                        <div class="row">
                            <div class="col-12 col-sm-8 question__text-wrapper">
                                <div class="question__content question__block">
                                    <div class="question__title question__block" v-if="question.title">
                                        <strong class="question__num">Frage {{ questionIndex + 1 }}:</strong>
                                        <div class="question__title-text">{{
                                        question.title
                                        }}</div>
                                    </div>
                                    <div
                                        v-if="question.tooltip"
                                        class="question__tooltip question__block"
                                    >
                                        <div class="icon-container">
                                            <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.3335 8.99992H7.66683V10.3333H6.3335V8.99992ZM6.3335 3.66659H7.66683V7.66658H6.3335V3.66659ZM7.00016 0.333252C3.3135 0.333252 0.333496 3.33325 0.333496 6.99992C0.333496 8.76803 1.03587 10.4637 2.28612 11.714C2.90517 12.333 3.6401 12.8241 4.44894 13.1591C5.25778 13.4941 6.12468 13.6666 7.00016 13.6666C8.76827 13.6666 10.464 12.9642 11.7142 11.714C12.9644 10.4637 13.6668 8.76803 13.6668 6.99992C13.6668 6.12444 13.4944 5.25753 13.1594 4.4487C12.8243 3.63986 12.3333 2.90493 11.7142 2.28587C11.0952 1.66682 10.3602 1.17575 9.55139 0.840722C8.74255 0.50569 7.87564 0.333252 7.00016 0.333252M7.00016 12.3333C5.58567 12.3333 4.22912 11.7713 3.22893 10.7712C2.22873 9.77096 1.66683 8.41441 1.66683 6.99992C1.66683 5.58543 2.22873 4.22888 3.22893 3.22868C4.22912 2.22849 5.58567 1.66659 7.00016 1.66659C8.41465 1.66659 9.7712 2.22849 10.7714 3.22868C11.7716 4.22888 12.3335 5.58543 12.3335 6.99992C12.3335 8.41441 11.7716 9.77096 10.7714 10.7712C9.7712 11.7713 8.41465 12.3333 7.00016 12.3333V12.3333Z" fill="#EE2485"/>
                                            </svg>
                                        </div>

                                        <span v-html="question.tooltip"></span>
                                    </div>
                                    <div v-if="question.text" class="question__text question__block">
                                        {{ question.text }}
                                    </div>
                                    <ul v-if="question.list" class="question__list question__block">
                                        <li v-for="(item, index) in question.list" :key="index"
                                            :class="{ 'question__list--subtext': item.subText }">
                                            <span v-if="item.text" v-html="$sanitizeHtml(item.text)"></span>
                                            <span v-if="item.subText" v-html="$sanitizeHtml(item.subText)"></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Typeahead } from 'uiv'

export default {
    components: { Typeahead },

    props: ['offer', 'success', 'insurerSelected', 'currentView', 'isPrintMode', 'filteredQuestions', 'inputs', 'getForm', 'getAnswers', 'getFieldClass', 'isSelected', 'checkAnswers', 'allQuestions', 'offerInsurers', 'selectedInsurer', 'serviceProviderString', 'selectedServiceProvider', 'serviceProvider', 'formData']
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.questions {
  &__title {
    color: #fff;
    background: #31A9E0;
    padding: 0.5rem 1.25rem;
    border-radius: 0.25rem;
    margin: 0 6px 0.75rem 6px;

    h2 {
      margin-bottom: 0;
      font-weight: normal;
    }
  }

  .question {
    page-break-inside: avoid;
    background: inherit;

    & + .question {
      margin-top: 0.75rem;
    }

    &__block + .question__block {
      margin-top: 0.5rem;
    }

    &__block + .question__additional-text {
      margin-top: 0.5rem;
    }

    &__title {
      display: flex;
    }

    &__tooltip {
      display: flex;
      align-items: flex-start;
      gap: 0.75rem;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    &__list {
      padding-left: 2.25rem;
      margin-bottom: 0;

      li + li {
        margin-top: 0.5rem;
      }

      li::before {
        left: -1.75rem;
      }
    }

    &__content {
      min-height: 4rem;
      background: #eaf6fc;
      padding: 1.25rem 1.5rem;
      border-radius: 0.25rem;
    }

    &__num {
      word-break: normal;
      word-wrap: normal;
      white-space: nowrap;
      margin-right: 0.5rem;
    }

    &__text-wrapper {
      max-width: 100%;
      width: 100%;
      flex: auto;
    }

    &__answers-wrapper {
      display: none !important;
    }
  }
}

.questions {
  & + & {
    margin-top: 1.25rem;
  }
}
</style>
