var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cyb-table gap-sm",class:{'cyb-table--offer': _vm.mode === 'offer'}},[_c('div',{staticClass:"statistics-row gap-sm",class:{'statistics-row--price-perform-winner': _vm.isPricePerformWinner()}},[_c('div',{staticClass:"sticky-col title-col"},[_c('div',{staticClass:"th-cell"},[_c('b',{staticClass:"mr-2"},[_vm._v("Jahresprämie")]),_vm._v(" (inkl. "+_vm._s(_vm.insTaxRate)+"% VSt.)")]),_c('transition',{attrs:{"name":"scores"}},[((_vm.application_name === 'portal-2' || _vm.isOffer) && _vm.isScoreVisible)?_c('ScoresInfoMakler',{attrs:{"isOffer":_vm.isOffer,"isAffiliate":_vm.isAffiliate}}):(_vm.isScoreVisible)?_c('ScoresInfo',{attrs:{"isOffer":_vm.isOffer,"isAffiliate":_vm.isAffiliate,"isScoreNotVisible":_vm.isScoreNotVisible}}):_vm._e()],1)],1),_c('div',{staticClass:"prices"},_vm._l((_vm.innerInsurers),function(insurer,insIndex){return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.getPriceToolTip(insurer)),expression:"getPriceToolTip(insurer)",modifiers:{"bottom":true}}],key:insurer.key,staticClass:"price",class:[
            `price--${_vm.insurers.length}`,
            {'price--disabled': insurer.disabled,
            'insurer-recommended': insurer.key === _vm.recommendedInsurer},
            _vm.isRecommendationDeclinedMixin(insurer.key) && _vm.isOffer ? 'insurer--recommendation-declined' : ''
        ]},[_c('div',{staticClass:"recommendation-frame"}),_c('div',{staticClass:"insurer-price th-cell text-center",class:{'price-perform-winner': _vm.isInsurerPricePerformWinner(insurer.key)},style:([_vm.isRecommendationDeclinedMixin(insurer.key) && _vm.mode === 'offer' ? {
            color: 'transparent'
          } : ''])},[(!_vm.isLoading)?_c('div',[(_vm.mode === 'offer')?_c('div',{staticClass:"insurer-price__title"},[_c('b',[_vm._v("Jahresprämie")])]):_vm._e(),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.$store.state.offer?.offer?.priceOverwrite?.[insurer.insurerKey]?.gross || insurer.prices?.gross || '',2))+" "),(_vm.getPriceToolTip(insurer))?_c('span',[_vm._v("*")]):_vm._e()]),(_vm.mode === 'offer')?_c('span',{staticClass:"insurer-price__subtitle"},[_vm._v(" (inkl. "+_vm._s(_vm.insTaxRate)+"% VSt.) ")]):_vm._e()]):_c('span',[_c('icon',{attrs:{"name":"loading","color":"white","spinning":true}})],1)]),_c('transition',{attrs:{"name":"scores"}},[(_vm.isScoreVisible)?_c('div',{staticClass:"tb-cell",class:{'tb-cell--winner': _vm.insurersWithScores[insIndex] && _vm.insurersWithScores[insIndex].precentageScore === 'winner'}},[_c('ProgressRing',{attrs:{"radius":70,"progress":_vm.insurersWithScores[insIndex] ? _vm.insurersWithScores[insIndex].precentageScore : null,"stroke":16}})],1):_vm._e()])],1)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }