<template>
  <div class="logo">
    <img :src="logo" v-if="logo && logo !== 'default'" alt="logo" class="img-fluid" />
    <img :src="defaultLogo" v-else-if="logo && logo === 'default'" alt="CyberDirekt logo" class="default-logo"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name:"HeaderLogo",
  props: {
    offer: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters([
      'isWWW',
    ]),

    logo() {
      if (this.offer && this.offer.logo) {
        if (this.offer.logo === 'no-logo') return null
        return this.offer.logo;
      }
      return 'default';
    },

    defaultLogo() {
      try {
        if (this.isWWW) {
          return 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/general/images/logo_blue.svg'
        } else {
          return 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/general/images/logo_blue.svg'
        }
      } catch (err) {
        console.error('cannot find logo', err)
      }
    }
  }
};
</script>

<style scoped>
.logo {
  max-width: 180px;
}
</style>
