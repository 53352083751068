<template>
  <div class="header">
    <div class="container">
      <div class="header-inner">
        <div class="header__logo">
          <img :src="logo" alt="logo">
        </div>

        <div v-if="Object.keys(cooperation).length" class="header__cooperation">
          <div>In Kooperation mit</div>
          <div class="header__partners-logo">
            <img :src="cooperation.partners.synaxon" alt="synaxon">
            <img :src="cooperation.partners.einsnulleins" alt="einsnulleins">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    logo: {
      type: String,
      default () {
        return 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/general/images/logo_blue.svg'
      }
    },
    cooperation: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/variables';
@import '../scss/mixins';
@import '../scss/tooltip';
@import '../scss/form';
@import '../scss/button';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.header {
  position: fixed;
  background: #fff;
  top: 0;
  left: 0;
  width: 100%;
  height: 71px;
  z-index: $zIndexHeader;

  &__logo {
    display: flex;
    height: 71px;
    align-items: center;

    img {
      max-width: 159px;
      min-width: 159px;
      width: 100%;
      max-height: 60px;
    }
  }

  &__cooperation {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.25rem;

    @include media-breakpoint-down(sm) {
      font-size: 0.75rem;
      justify-content: flex-end;
    }

    @include media-breakpoint-down(xs) {
      flex-direction: column;
      align-items: flex-end;
      gap: 0.75rem;
    }
  }

  &__partners-logo {
    display: flex;
    // flex-direction: column;
    align-items: center;
    gap: 1rem;

    img {
      max-width: 110px;
      min-width: 110px;
    }

    img:nth-child(2) {
      margin-top: -15px;
    }

    @include media-breakpoint-down(sm) {
      img {
        max-width: 70px;
      }

      img:nth-child(2) {
        margin-top: -10px;
      }
    }
  }

  &.affiliate {
    &--hornetsecurity {
      .header__logo {
        // height: 50px;
        height: 100%;

        img {
          margin-left: 16px;
          margin-top: 4px;
          height: 50px;
        }

        @include media-breakpoint-down(md) {
          img {
          margin-left: 8px;
            margin-top: 6px;
            height: 50px;
          }
        }

        @include media-breakpoint-down(sm) {
          img {
            margin-left: 0;
            margin-top: 10px;
            height: 50px;
          }
        }
      }
    }
  }
}

.header-inner {
  display: flex;
  align-items: center;
}
</style>
