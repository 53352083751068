import ConditionCheck from '../ConditionCheck'
import tariffsWithScanMixin from './tariffsWithScanMixin.js'
// reworked so it returns an array instead of the banner object

export default {
  mixins: [tariffsWithScanMixin],
  computed: {
    inputs() {
      return this.$store.state.checkout.application.inputs
    },
    agencyKey() {
      if (this.mode === 'offer') {
        return this.$store.state.offer.offer.key
      } else if (this.$store.state.checkout.application.style === 'affiliate' && this.$store.state.checkout.application.affiliateBroker) {
        return this.$store.state.checkout.application.affiliateBroker.agency
      }
      return this.$store.getters.agencyKey
    },
  },
  methods: {
    getBanner(insurer) {
      if (!insurer.banner) {
        return []
      }

      if (
        ((this.$store.state.user &&
          this.$store.state.user.email === 'info@auxmed.de' &&
          this.isBroker) ||
          (this.affiliateBroker && this.affiliateBroker.agency === 'auxmed')) &&
        this.inputs.industry.w === '86230' &&
        insurer.key === 'victor-gesundheitswesen-heilwesen'
      ) {
        if (this.inputs.deductible === 500) {
          return [{
            text: 'Spezialkonzept FVDZ*',
            short: 'Spezialkonzept für Mitglieder des FVDZ*',
            long:
              'Spezialkonzept für Mitglieder des FVDZ mit reduziertem Selbstbehalt. Es gilt ein Selbstbehalt von € 250'
          }]
        } else if (this.inputs.deductible === 1000) {
          return [{
            text: 'Spezialkonzept FVDZ*',
            short: 'Spezialkonzept für Mitglieder des FVDZ*',
            long:
              'Spezialkonzept für Mitglieder des FVDZ mit reduziertem Selbstbehalt. Es gilt ein Selbstbehalt von € 500'
          }]
        } else if (this.inputs.deductible === 2500) {
          return [{
            text: 'Spezialkonzept FVDZ*',
            short: 'Spezialkonzept für Mitglieder des FVDZ*',
            long:
              'Spezialkonzept für Mitglieder des FVDZ mit reduziertem Selbstbehalt. Es gilt ein Selbstbehalt von € 1.250'
          }]
        } else if (this.inputs.deductible === 5000) {
          return [{
            text: 'Spezialkonzept FVDZ*',
            short: 'Spezialkonzept für Mitglieder des FVDZ*',
            long:
              'Spezialkonzept für Mitglieder des FVDZ mit reduziertem Selbstbehalt. Es gilt ein Selbstbehalt von € 2.500'
          }]
        }
      }

      // special Bitkom banner - affiliate link
      if(insurer.key.includes('victor') && this.inputs.netTurnOver > 5000000 && this.affiliateBroker && this.affiliateBroker.agency === 'bitkom'){
        return [{
          text: 'Sonderaktion Bitkom',
          short: 'Exklusiver Rabatt von 10% für Mitglieder des Bitkom',
          long: 'Exklusiver Rabatt von 10% für Mitglieder des Bitkom'
        }];
      }

      let bannersArr = [];
      if (insurer.banner && Array.isArray(insurer.banner)) {

        insurer.banner.forEach((banner) => {
          const newBan = {}
          if (
            ConditionCheck.check(
              this.$store.state.checkout.application.inputs,
              banner.condition,
              this.$store.state.checkout.application,
              this.agencyKey ? this.agencyKey : null
            )
          ) {
            if (banner.style) {
              newBan.style = banner.style
            }
            if (banner.color) {
              newBan.color = banner.color
            }
            if (banner.short) {
              newBan.short = banner.short
            }
            if (banner.text) {
              newBan.text = banner.text
            }
            if (banner.long) {
              newBan.long = banner.long
            }
            Object.keys(newBan).length ? bannersArr.push(newBan) : ''
          }
        }, this)
      } else {
        bannersArr[ {
        color: insurer.banner.color,
        style: insurer.banner.style,
        short: insurer.banner.short,
        text: insurer.banner.text,
          long: insurer.banner.long
        }]
      }
      // if there is a priceoverwrite in offer, remove the indikation banner
      // because it means the price has been already set after the scan.
      if (
        (this.isQuoteCheckDoneMixin(insurer.key) && this.mode === 'offer' )||
        (this.isRecommendationDeclinedMixin(insurer.key) && this.mode === 'offer' ) ||
        this.mode === 'offer' &&
        this.$store.state.offer?.offer?.priceOverwrite &&
        this.$store.state.offer.offer.priceOverwrite[insurer.key]
      ) {
        bannersArr = bannersArr.filter((b) => {
          return b.short !== 'Indikation*'
        })
      }

      return bannersArr
    },
    getBannerClass(insurer) {
      if (!insurer.banner) return
      if (
        insurer.banner.length > 0 &&
        ConditionCheck.check(this.inputs, insurer.banner.condition) &&
        insurer.banner.style
      ) {
        return `insurer__banner--${insurer.banner.style}`
      }
    },
    hasBanner(insurer) {
      const banners = this.getBanner(insurer)
      if (banners[0]){

        return banners[0].text || null
      } else {
        return null
      }
    },
  }
}

