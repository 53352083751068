var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"insurers-wrapper"},[_c('table',[_c('thead',[_c('tr',[_vm._m(0),_vm._l((_vm.innerInsurers),function(insurer,iIndex){return _c('th',{key:`print-${iIndex}`,staticClass:"tb-cell insurer",class:[{'insurer--disabled': insurer.disabled}, `th-cell--${_vm.insurers.length}`]},[_c('div',{staticClass:"insurer__image",class:['insurer__image--' + _vm.getInsurerKey(insurer.key)]},[_c('div',{staticClass:"th-content"},[_c('img',{attrs:{"src":_vm.getInsurerLogoPath(insurer),"alt":insurer.label}})])])])})],2),_c('tr',{staticClass:"print-scores",class:{'print-scores--price-perform-winner': _vm.isPricePerformWinner()}},[_c('th',{staticClass:"first-col-wrapper"},[_c('div',{staticClass:"first-col th-col"},[_c('b',{staticClass:"mr-2"},[_vm._v("Jahresprämie")]),_vm._v(" "),_c('span',{staticClass:"text-sm"},[_vm._v("(inkl. "+_vm._s(_vm.insTaxRate)+"% VSt.)")])])]),_vm._l((_vm.insurers),function(insurer){return _c('th',{key:insurer.key,staticClass:"price th-col",class:[
          {
            'price--disabled': insurer.disabled,
            'price-perform-winner': _vm.isInsurerPricePerformWinner(insurer.key)
          },
          `th-col--${_vm.insurers.length}`
        ]},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.getPriceToolTip(insurer)),expression:"getPriceToolTip(insurer)",modifiers:{"bottom":true}}],staticClass:"th-col-content"},[_c('span',{style:([_vm.isRecommendationDeclinedMixin(insurer.key) ? {
            color: 'transparent'
          } : ''])},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.$store.state.offer?.offer?.priceOverwrite?.[insurer.insurerKey]?.gross || insurer.prices?.gross || '',2))+" "),(_vm.getPriceToolTip(insurer))?_c('span',[_vm._v("*")]):_vm._e()])])])})],2),_c('tr',{staticClass:"price-spacer"})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"first-col-wrapper"},[_c('div',{staticClass:"first-col th-col"})])
}]

export { render, staticRenderFns }